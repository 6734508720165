import { render, staticRenderFns } from "./CmdRef.vue?vue&type=template&id=3735e652&"
import script from "./CmdRef.vue?vue&type=script&lang=js&"
export * from "./CmdRef.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/px/Github/chandler-docs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3735e652')) {
      api.createRecord('3735e652', component.options)
    } else {
      api.reload('3735e652', component.options)
    }
    module.hot.accept("./CmdRef.vue?vue&type=template&id=3735e652&", function () {
      api.rerender('3735e652', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "pages/CmdRef.vue"
export default component.exports