var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chunk container content" }, [
    _c("div", { staticClass: "text-muted text-center mb-4" }, [
      _vm._v("\n    [args] are required, (args) are optional.\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "sys" } },
      [
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "~/help (command)",
              group: "sys",
              level: "1",
              show: "true"
            }
          },
          [
            _vm._v(
              "\n      Returns a link to this site and the support server, as well as an invite link for the bot. When passed a "
            ),
            _c("code", [_vm._v("(command)")]),
            _vm._v(", returns the help string for that command.\n    ")
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/commands", group: "sys", level: "1" } },
          [
            _vm._v(
              "\n      Returns a list of all the commands available to you. (All the commands you can use.)\n      "
            ),
            _c("code", { staticClass: "alias" }, [_vm._v("~/cmds")])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "zone" } },
      [
        _c("h5", [_vm._v("Tracking Timezones")]),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "~/time (time/username)",
              group: "zone",
              level: "1",
              show: "true"
            }
          },
          [
            _vm._v("\n      Gets the time at "),
            _c("code", [_vm._v("(time)")]),
            _vm._v(" for all the timezones on the server. If "),
            _c("code", [_vm._v("(time)")]),
            _vm._v(" is not provided, gets time now. "),
            _c("br"),
            _vm._v(
              "\n      Remember, try not to ding a user if looking up what time it is, just in case. You can look up by nickname or username!\n      "
            ),
            _c("code", { staticClass: "example" }, [_vm._v("~/time")]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [_vm._v("~/time 9pm")]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [_vm._v("~/time Username")])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/zone [timezone]", group: "zone", level: "1" } },
          [
            _vm._v(
              "\n      Sets your timezone for local time management. Takes a timezone string, or the city name your timezone is based on, like "
            ),
            _c("code", [_vm._v("New York")]),
            _vm._v(", "),
            _c("code", [_vm._v("Amsterdam")]),
            _vm._v(", or "),
            _c("code", [_vm._v("Melbourne")]),
            _vm._v(". You can use "),
            _c(
              "a",
              {
                attrs: {
                  href: "http://kevalbhatt.github.io/timezone-picker/",
                  target: "_blank"
                }
              },
              [_vm._v("this site")]
            ),
            _vm._v(" to find your "),
            _c("code", [_vm._v("timezone")]),
            _vm._v(".\n      "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/zone Los Angeles")
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/zone Europe/Berlin")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "~/setzone [user] [timezone]",
              group: "zone",
              level: "3"
            }
          },
          [
            _vm._v(
              "\n      Sets user's timezone for local time management. Takes a timezone string, see "
            ),
            _c("code", [_vm._v("(~/zone)")]),
            _vm._v(" above for more.\n      "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/zone user Los Angeles")
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/zone user Europe/Berlin")
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "note" } },
      [
        _c("h5", [_vm._v("Custom Notes / Commands")]),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/notes", group: "note", level: "1" } },
          [_vm._v("\n      Returns a list of the notes in the server.\n    ")]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "~/write [cmd] [message]",
              group: "note",
              level: "3",
              show: "true"
            }
          },
          [
            _vm._v("\n      Creates a note for "),
            _c("code", [_vm._v("(message)")]),
            _vm._v(" that can be accessed via "),
            _c("code", [_vm._v("~/cmd")]),
            _vm._v(
              ". Only mods can create a note, but anyone can use them.\n      "
            ),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/write yo hello there")
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [_vm._v("~/yo")]),
            _vm._v(" "),
            _c("code", { staticClass: "outputs" }, [_vm._v("hello there")])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/erase [note]", group: "note", level: "3" } },
          [
            _vm._v("\n      Deletes "),
            _c("code", [_vm._v("note.")]),
            _vm._v(".\n    ")
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "conf" } },
      [
        _c("h5", [_vm._v("Bot Configuration")]),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/status", group: "conf", level: "5" } },
          [
            _vm._v(
              "\n      Returns details about current bot configuration.\n      "
            ),
            _c("code", { staticClass: "alias" }, [_vm._v("~/configs")])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: { cmd: "~/set [option] [value]", group: "conf", level: "5" }
          },
          [
            _c("code", { staticClass: "example" }, [_vm._v("~/set prefix ?")]),
            _vm._v("\n      Change the prefix to "),
            _c("strong", [_vm._v("?")]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/set warnings off")
            ]),
            _vm._v(
              "\n      Disables the warning message for using a command you don't have access to.\n      "
            ),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/set mods @Role")
            ]),
            _vm._v("\n      Sets the "),
            _c("strong", [_vm._v("role")]),
            _vm._v(
              " that Chandler will identify as Moderators. If the role is named "
            ),
            _c("em", [_vm._v("Mod")]),
            _vm._v(" or "),
            _c("em", [_vm._v("Moderator")]),
            _vm._v(", he'll pick them up automatically.\n      "),
            _c("code", { staticClass: "alias" }, [_vm._v("~/config")])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/roles", group: "conf", level: "3" } },
          [
            _vm._v(
              "\n      Prints out all the roles in the server and their IDs.\n    "
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "mods" } },
      [
        _c("h5", [_vm._v("Server Management")]),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "~/clear [x]",
              group: "mods",
              level: "3",
              show: "true"
            }
          },
          [
            _vm._v("\n      Deletes "),
            _c("strong", [_vm._v("x")]),
            _vm._v(
              " messages from the channel, up to 300. Chandler will attempt to bulk delete the messages, but if they are over 2 weeks old Discord will only allow them to be deleted one at a time, so it may take a while\n      "
            ),
            _c("code", { staticClass: "example" }, [_vm._v("~/clear 24")])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/say", group: "mods", level: "3" } },
          [
            _vm._v("\n      Repeats "),
            _c("code", [_vm._v("(message)")]),
            _vm._v(
              " in the channel, and deletes the original message.\n      "
            ),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/say hello there")
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [
              _vm._v("Chandler: Hello there")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          { attrs: { cmd: "~/post [object]", group: "mods", level: "3" } },
          [
            _vm._v("\n      Input an "),
            _c("code", [_vm._v("(embed object)")]),
            _vm._v(", such as the one generated from "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://leovoel.github.io/embed-visualizer/",
                  target: "_blank"
                }
              },
              [_vm._v("here")]
            ),
            _vm._v(", and the bot embeds it in the channel.\n      "),
            _c("code", { staticClass: "example" }, [
              _vm._v('~/post { "title": "hello!" }')
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "alias" }, [_vm._v("~/embed")])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "~/edit [messageID] [content/embed]",
              group: "mods",
              level: "3"
            }
          },
          [
            _vm._v("\n      Edit message (sent by the bot!) with id "),
            _c("code", [_vm._v("(messageID)")]),
            _vm._v(", and replace it with "),
            _c("code", [_vm._v("(content/embed)")]),
            _vm._v(". "),
            _c("br"),
            _vm._v("\n      Follow "),
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://support.discordapp.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-",
                  target: "_blank"
                }
              },
              [_vm._v("this link")]
            ),
            _vm._v(" to learn how to copy Discord message IDs.\n      "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/edit MESSAGE hello!")
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [
              _vm._v('~/edit MESSAGE { "title": "hello!" }')
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }