<template>
  <div class="chunk container content">
      
    <div class="text-muted text-center mb-4">
      [args] are required, (args) are optional.
    </div>

    <div class="accordion" id="sys">
      <doc-block cmd="~/help (command)" group="sys" level="1" show="true">
        Returns a link to this site and the support server, as well as an invite link for the bot. When passed a <code>(command)</code>, returns the help string for that command.
      </doc-block>

      <doc-block cmd="~/commands" group="sys" level="1">
        Returns a list of all the commands available to you. (All the commands you can use.)
        <code class="alias">~/cmds</code>
      </doc-block>
    </div>

    <div class="accordion" id="zone">
      <h5>Tracking Timezones</h5>

      <doc-block cmd="~/time (time/username)" group="zone" level="1" show="true">
        Gets the time at <code>(time)</code> for all the timezones on the server. If <code>(time)</code> is not provided, gets time now. <br>
        Remember, try not to ding a user if looking up what time it is, just in case. You can look up by nickname or username!
        <code class="example">~/time</code>
        <code class="example">~/time 9pm</code>
        <code class="example">~/time Username</code>
      </doc-block>

      <doc-block cmd="~/zone [timezone]" group="zone" level="1">
        Sets your timezone for local time management. Takes a timezone string, or the city name your timezone is based on, like <code>New York</code>, <code>Amsterdam</code>, or <code>Melbourne</code>. You can use <a href="http://kevalbhatt.github.io/timezone-picker/" target="_blank">this site</a> to find your <code>timezone</code>.
        <code class="example">~/zone Los Angeles</code>
        <code class="example">~/zone Europe/Berlin</code>
      </doc-block>

      <doc-block cmd="~/setzone [user] [timezone]" group="zone" level="3">
        Sets user's timezone for local time management. Takes a timezone string, see <code>(~/zone)</code> above for more.
        <code class="example">~/zone user Los Angeles</code>
        <code class="example">~/zone user Europe/Berlin</code>
      </doc-block>
    </div>

    <div class="accordion" id="note">
      <h5>Custom Notes / Commands</h5>

      <doc-block cmd="~/notes" group="note" level="1">
        Returns a list of the notes in the server.
      </doc-block>

      <doc-block cmd="~/write [cmd] [message]" group="note" level="3" show="true">
        Creates a note for <code>(message)</code> that can be accessed via <code>~/cmd</code>. Only mods can create a note, but anyone can use them.
        <code class="example">~/write yo hello there</code>
        <code class="example">~/yo</code>
        <code class="outputs">hello there</code>
      </doc-block>

      <doc-block cmd="~/erase [note]" group="note" level="3">
        Deletes <code>note.</code>.
      </doc-block>
    </div>

    <div class="accordion" id="conf">
      <h5>Bot Configuration</h5>

      <doc-block cmd="~/status" group="conf" level="5">
        Returns details about current bot configuration.
        <code class="alias">~/configs</code>
      </doc-block>

      <doc-block cmd="~/set [option] [value]" group="conf" level="5">
        <code class="example">~/set prefix ?</code>
        Change the prefix to <strong>?</strong>
        <code class="example">~/set warnings off</code>
        Disables the warning message for using a command you don't have access to.
        <code class="example">~/set mods @Role</code>
        Sets the <strong>role</strong> that Chandler will identify as Moderators. If the role is named <em>Mod</em> or <em>Moderator</em>, he'll pick them up automatically.
        <code class="alias">~/config</code>
      </doc-block>

      <doc-block cmd="~/roles" group="conf" level="3">
        Prints out all the roles in the server and their IDs.
      </doc-block>
    </div>

    <div class="accordion" id="mods">
      <h5>Server Management</h5>

      <doc-block cmd="~/clear [x]" group="mods" level="3" show="true">
        Deletes <strong>x</strong> messages from the channel, up to 300. Chandler will attempt to bulk delete the messages, but if they are over 2 weeks old Discord will only allow them to be deleted one at a time, so it may take a while
        <code class="example">~/clear 24</code>
      </doc-block>

      <doc-block cmd="~/say" group="mods" level="3">
        Repeats <code>(message)</code> in the channel, and deletes the original message.
        <code class="example">~/say hello there</code>
        <code class="example">Chandler: Hello there</code>
      </doc-block>

      <doc-block cmd="~/post [object]" group="mods" level="3">
        Input an <code>(embed object)</code>, such as the one generated from <a href="https://leovoel.github.io/embed-visualizer/" target="_blank">here</a>, and the bot embeds it in the channel.
        <code class="example">~/post { "title": "hello!" }</code>
        <code class="alias">~/embed</code>
      </doc-block>

      <doc-block cmd="~/edit [messageID] [content/embed]" group="mods" level="3">
        Edit message (sent by the bot!) with id <code>(messageID)</code>, and replace it with <code>(content/embed)</code>. <br>
        Follow <a href="https://support.discordapp.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-" target="_blank">this link</a> to learn how to copy Discord message IDs.
        <code class="example">~/edit MESSAGE hello!</code>
        <code class="example">~/edit MESSAGE { "title": "hello!" }</code>
      </doc-block>
    </div>

  </div>
</template>

<script>
  import DocBlock from '../parts/DocBlock.vue'
  export default { components: { DocBlock } }
</script>