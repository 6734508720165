var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "chandler" } },
    [
      _c(
        "nav",
        {
          staticClass: "navbar navbar-expand-lg navbar-light bg-light fixed-top"
        },
        [
          _c("div", { staticClass: "container" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse",
                attrs: { id: "menu" }
              },
              [
                _c(
                  "ul",
                  { staticClass: "navbar-nav mr-auto" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-item",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/",
                          exact: ""
                        }
                      },
                      [_c("a", { staticClass: "nav-link" }, [_vm._v("Home")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-item",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/cmds"
                        }
                      },
                      [
                        _c("a", { staticClass: "nav-link" }, [
                          _vm._v("Commands")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-item",
                        attrs: {
                          tag: "li",
                          "active-class": "active",
                          to: "/guides"
                        }
                      },
                      [_c("a", { staticClass: "nav-link" }, [_vm._v("Guides")])]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(2)
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } }, [
      _c("img", { attrs: { src: "logo.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "navbar-toggler",
        attrs: { href: "#", "data-toggle": "collapse", "data-target": "#menu" }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "navbar-nav" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: "https://discord.gg/uyVcGKu" }
          },
          [_vm._v("Support Server")]
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: "https://github.com/12px/chandler" }
          },
          [_vm._v("Github")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }