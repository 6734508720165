var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chunk content container" }, [
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "zones" } },
      [
        _c("h5", [_vm._v("Tracking Timezones")]),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "Adding a timezone",
              group: "zones",
              name: "add",
              show: "true"
            }
          },
          [
            _vm._v("\n      You can add your own timezone by using "),
            _c("code", [_vm._v("~/zone [timezone]")]),
            _vm._v(". "),
            _c("br"),
            _vm._v(
              "\n      You can set someone else's timezone (if you're a mod) by doing "
            ),
            _c("code", [_vm._v("~/setzone [timezone]")]),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v("\n      You can click "),
            _c(
              "a",
              {
                attrs: { href: "http://kevalbhatt.github.io/timezone-picker/" }
              },
              [_vm._v("This Link")]
            ),
            _vm._v(" to \n      go to a timezone picker. Running "),
            _c("code", [_vm._v("~/help time")]),
            _vm._v(" will also return this link.\n      "),
            _c("code", { staticClass: "example" }, [_vm._v("~/zone new york")]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/setzone username sydney")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: { cmd: "Getting The Time", group: "zones", name: "custom" }
          },
          [
            _c("code", { staticClass: "example" }, [_vm._v("~/time")]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [_vm._v("~/time 9pm")]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [_vm._v("~/time username")])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion", attrs: { id: "notes" } },
      [
        _c("h5", [_vm._v("Using Custom Notes")]),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: {
              cmd: "Image / Gif Responses",
              group: "notes",
              name: "image",
              show: "true"
            }
          },
          [
            _vm._v(
              "\n      Using notes for images and gifs is easy, just set the URL as the note, and it gets embedded when Chandler fires the note!\n      "
            ),
            _c("code", { staticClass: "example" }, [
              _vm._v(
                "~/note mondays https://gfycat.com/regalhelplessgreyhounddog"
              )
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [_vm._v("~/mondays")]),
            _vm._v(" "),
            _c("code", { staticClass: "outputs" }, [
              _vm._v("https://gfycat.com/regalhelplessgreyhounddog")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "doc-block",
          {
            attrs: { cmd: "Custom Responses", group: "notes", name: "custom" }
          },
          [
            _vm._v("\n      You can use "),
            _c("code", [_vm._v("{msg}")]),
            _vm._v(" to pass a message to a note.\n      "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/note hugs *hugs {msg}*")
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "example" }, [
              _vm._v("~/hugs everybody!")
            ]),
            _vm._v(" "),
            _c("code", { staticClass: "outputs" }, [
              _vm._v("*hugs everybody!*")
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }